import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { profferRoutes } from "../../../proffer_module/routes/routes";
import useOutsideClick from "../../hooks/useOutsideClick";
import { sharedRoutes } from "../../routes/routes";
import { adventureRoutes } from "../../../adventures_module/routes/routes";
import { ccfRoutes } from "../../../ccfWebsite_module/routes/routes";
import { medEvalRoutes } from "../../../medEvaluation_module/routes/routes";
import { IoReorderThreeOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

const PrimaryHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const svcMenu = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  useOutsideClick(svcMenu, () => setIsOpen(false));

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavToInBuild = (title: string) => {
    navigate(sharedRoutes.coming_soon, { state: { title } });
  };

  return (
    <div className="bg-white">
      <div className="container flex flex-row py-3 justify-between items-center">
        <Link to="/">
          <img
            src="https://a360csastorage.blob.core.windows.net/childcare/web-logo.png"
            alt="web logo"
            className="min-w-[126px]"
          />
        </Link>

        {/* Mobile menu toggle button */}
        <button
          className="xl:hidden text-2xl"
          onClick={toggleMobileMenu}
          aria-label="Toggle Mobile Menu"
        >
          {isMobileMenuOpen ? <IoClose /> : <IoReorderThreeOutline />}
        </button>

        {/* Main Navigation */}

        <nav
          className={`fixed top-0 left-0 h-full w-64 xl:w-full bg-white z-20 transform transition-transform duration-300 ease-in-out ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          } xl:static xl:translate-x-0 xl:flex xl:flex-row xl:items-center xl:gap-7 xl:justify-end`}
        >
          <div className="flex justify-between items-center p-4 border-b border-gray-200 xl:hidden">
            <Link to="/">
              <img
                src="https://a360csastorage.blob.core.windows.net/childcare/web-logo.png"
                alt="web logo"
                className="min-w-[126px]"
              />
            </Link>
            <button
              className="text-2xl"
              onClick={toggleMobileMenu}
              aria-label="Close Mobile Menu"
            >
              <IoClose />
            </button>
          </div>

          <ul className="flex flex-col xl:flex-row items-center gap-4 xl:gap-7 mt-8 xl:mt-0 p-4 xl:p-0">
            <li>
              <Link
                to={ccfRoutes.home}
                className="text-lg text-[#000000] active:font-semibold active:text-primary hover:text-primary px-3"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to={sharedRoutes.about_us}
                className="text-lg text-[#000000] hover:text-primary px-3"
              >
                About us
              </Link>
            </li>
            <li>
              <div className="relative" id="dropdown-menu">
                <div
                  className="inline-flex items-center overflow-hidden "
                  onMouseOver={() => setIsOpen(true)}
                >
                  <button
                    id="menu-toggle-button"
                    className="flex items-center text-gray-600 hover:text-gray-700"
                    onClick={toggleMenu}
                  >
                    <span className="text-lg text-[#000000] hover:text-primary px-3">
                      Services
                    </span>
                    <span className="sr-only">Menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                {isOpen && (
                  <div
                    id="menu-items"
                    className="absolute end-0 z-10 mt-2 w-max rounded-md border border-gray-100 bg-white shadow-lg"
                    role="menu"
                    ref={svcMenu}
                    onMouseLeave={() => setIsOpen(false)}
                  >
                    <div className="p-2">
                      <Link
                        to={adventureRoutes.home}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        Adventures
                      </Link>
                      <Link
                        to={medEvalRoutes.eval_center_home}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        Medical Evaluation
                      </Link>
                      {/* <Link
                        to={profferRoutes.home}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        PrOffer
                      </Link>
                      <button
                        onClick={() => {
                          handleNavToInBuild("Foray");
                          setIsOpen(false);
                        }}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        Foray
                      </button> */}
                      {/* <button
                        onClick={() => {
                          handleNavToInBuild("LMS");
                          setIsOpen(false);
                        }}
                        id="view-storefront"
                        className="block rounded-lg px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary w-full text-start"
                        role="menuitem"
                      >
                        LMS
                      </button> */}
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li>
              <Link
                to={sharedRoutes.blogs_screen}
                className="text-lg text-[#000000] hover:text-primary px-3"
              >
                Blogs
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  handleNavToInBuild("Consultation");
                }}
                className="text-lg text-[#000000] hover:text-primary px-3"
              >
                Consultation
              </button>
            </li>
            <li>
              <Link
                to={sharedRoutes.contact_us}
                className="text-lg text-[#000000] hover:text-primary px-3"
              >
                Contact us
              </Link>
            </li>
          </ul>
          <ul className="flex flex-col items-center gap-4 xl:gap-7 xl:hidden">
            <li>
              <Link
                to={profferRoutes.home}
                className="text-lg text-secondary hover:text-primary"
              >
                PrOffer
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  handleNavToInBuild("Foray");
                }}
                className="text-lg text-secondary hover:text-primary"
              >
                Foray
              </button>
            </li>

            <li>
              <Link
                to={medEvalRoutes.eval_center_home}
                className="text-lg text-secondary hover:text-primary"
              >
                Medical Evaluation
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Overlay for mobile menu */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10 xl:hidden"
          onClick={toggleMobileMenu}
        />
      )}
    </div>
  );
};

export default PrimaryHeader;
