export const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const decimalPart = rating % 1;
    const halfStars = decimalPart >= 0.5 && decimalPart < 0.6 ? 1 : 0;
    const quarterStars = decimalPart >= 0.1 && decimalPart < 0.5 ? 1 : 0;
    const threeQuarterStars = decimalPart >= 0.6 ? 1 : 0;
    const emptyStars =
      5 - fullStars - halfStars - quarterStars - threeQuarterStars;

    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <img
            key={`full-${index}`}
            className="w-4 h-4"
            src="../images/Star-Solid-Full.svg"
            alt="full star"
          />
        ))}
        {quarterStars === 1 && (
          <img
            className="w-4 h-4"
            src="../images/Star-Solid-25.svg"
            alt="quarter star"
          />
        )}
        {halfStars === 1 && (
          <img
            className="w-4 h-4"
            src="../images/Star-Solid-50.svg"
            alt="half star"
          />
        )}
        {threeQuarterStars === 1 && (
          <img
            className="w-4 h-4"
            src="../images/Star-Solid-75.svg"
            alt="three-quarter star"
          />
        )}
        {[...Array(emptyStars)].map((_, index) => (
          <img
            key={`empty-${index}`}
            className="w-4 h-4"
            src="../images/unfille-rating-star.svg"
            alt="empty star"
          />
        ))}
      </>
    );
  };
