export const initBookAppointment = {
    name:"",
    email:"",
    state: "",
    service: "",
    dateTime: ""
}

export const initBookAppointmentError = {
    nameError:"",
    emailError:"",
    stateError: "",
    serviceError: "",
    dateTimeError: ""
}
