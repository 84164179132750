import CenterViewBanner from "../../../shared/components/centerViewBanner/CenterViewBanner";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import CenterBookingSelector from "../../components/bookSelectors/CenterBookingSelector";
import HeroBanner from "../../components/medEvaluationBanner/HeroBanner";

const CenterBooking = () => {
  return (
    <>
      <MainWrapper>
        <div>
          <HeroBanner
            highlightedText=""
            title="Book Appointment"
            paragraphText=""
            showStats={true}
            bgClass="https://a360csastorage.blob.core.windows.net/childcare/46b1c025-41a4-47ae-a362-99a5d145dc48-fileName-med-date-banner.png"
          />
          <div className="container">
            <CenterBookingSelector />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default CenterBooking;
