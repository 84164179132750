import React, { useEffect } from "react";
import MainWrapper from "../../components/layout/MainWrapper";

const TermsConditions = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <div className="container py-8 lg:py-16 xl:py-24">
          <h2 className="text-2xl md:text-xll xl:text-4xl font-semibold text-secondary text-center mb-4 md:mb-10 xl:mb-16">
            Terms & Condition
          </h2>
          <div className="space-y-6">
            <details
              className="group [&_summary::-webkit-details-marker]:hidden border rounded-lg p-5"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-3 text-gray-900">
                <button className="text-2xl text-secondary font-semibold">
                  1. Introduction{" "}
                </button>

                <svg
                  className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>
              <div className="mt-4 leading-relaxed">
                <p className="text-base text-secondaryVariant">
                  Lorem ipsum dolor sit amet, consectetur cdolor col adipiscing
                  elit. Integer mattis nunc augue vel lacinia erat euismod ut.
                  Sed eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.Lorem ipsum dolor sit amet, consectetur cdolor col
                  adipiscing elit. Integer mattis nunc augue vel lacinia erat
                  euismod ut. Sed eleifend tellus nonole tincidunt aliquet.
                  Fusce aliquam mi felis.Lorem ipsum dolor sit amet, consectetur
                  cdolor col adipiscing elit. Integer mattis nunc augue vel
                  lacinia erat euismod ut. Sed eleifend tellus nonole tincidunt
                  aliquet. Fusce aliquam mi felis.
                </p>
              </div>
            </details>
            <details
              className="group [&_summary::-webkit-details-marker]:hidden border rounded-lg p-5"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-3 text-gray-900">
                <button className="text-2xl text-secondary font-semibold">
                  2. Your Acceptance of this agreement{" "}
                </button>

                <svg
                  className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>
              <div className="mt-4 leading-relaxed">
                <p className="text-base text-secondaryVariant">
                  Lorem ipsum dolor sit amet, consectetur cdolor col adipiscing
                  elit. Integer mattis nunc augue vel lacinia erat euismod ut.
                  Sed eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.Lorem ipsum dolor sit amet, consectetur cdolor col
                  adipiscing elit. Integer mattis nunc augue vel lacinia erat
                  euismod ut. Sed eleifend tellus nonole tincidunt aliquet.
                  Fusce aliquam mi felis.Lorem ipsum dolor sit amet, consectetur
                  cdolor col adipiscing elit. Integer mattis nunc augue vel
                  lacinia erat euismod ut. Sed eleifend tellus nonole tincidunt
                  aliquet. Fusce aliquam mi felis.Lorem ipsum dolor sit amet,
                  consectetur cdolor col adipiscing elit. Integer mattis nunc
                  augue vel lacinia erat euismod ut. Sed eleifend tellus nonole
                  tincidunt aliquet. Fusce aliquam mi felis.Lorem ipsum dolor
                  sit amet, consectetur cdolor col adipiscing elit. Integer
                  mattis nunc augue vel lacinia erat euismod ut. Sed eleifend
                  tellus nonole tincidunt aliquet. Fusce aliquam mi felis.Lorem
                  ipsum dolor sit amet, consectetur cdolor col adipiscing elit.
                  Integer mattis nunc augue vel lacinia erat euismod ut. Sed
                  eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.
                </p>
              </div>
            </details>
            <details
              className="group [&_summary::-webkit-details-marker]:hidden border rounded-lg p-5"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-3 text-gray-900">
                <button className="text-2xl text-secondary font-semibold">
                  3. About these terms{" "}
                </button>

                <svg
                  className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>
              <div className="mt-4 leading-relaxed">
                <p className="text-base text-secondaryVariant">
                  Lorem ipsum dolor sit amet, consectetur cdolor col adipiscing
                  elit. Integer mattis nunc augue vel lacinia erat euismod ut.
                  Sed eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.Lorem ipsum dolor sit amet, consectetur cdolor col
                  adipiscing elit. Integer mattis nunc augue vel lacinia erat
                  euismod ut. Sed eleifend tellus nonole tincidunt aliquet.
                  Fusce aliquam mi felis.Lorem ipsum dolor sit amet, consectetur
                  cdolor col adipiscing elit. Integer mattis nunc augue vel
                  lacinia erat euismod ut. Sed eleifend tellus nonole tincidunt
                  aliquet. Fusce aliquam mi felis.Lorem ipsum dolor sit amet,
                  consectetur cdolor col adipiscing elit. Integer mattis nunc
                  augue vel lacinia erat euismod ut. Sed eleifend tellus nonole
                  tincidunt aliquet. Fusce aliquam mi felis.Lorem ipsum dolor
                  sit amet, consectetur cdolor col adipiscing elit. Integer
                  mattis nunc augue vel lacinia erat euismod ut. Sed eleifend
                  tellus nonole tincidunt aliquet. Fusce aliquam mi felis.Lorem
                  ipsum dolor sit amet, consectetur cdolor col adipiscing elit.
                  Integer mattis nunc augue vel lacinia erat euismod ut. Sed
                  eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.
                </p>
              </div>
            </details>
            <details
              className="group [&_summary::-webkit-details-marker]:hidden border rounded-lg p-5"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-3 text-gray-900">
                <button className="text-2xl text-secondary font-semibold">
                  4. Problems and dispute{" "}
                </button>

                <svg
                  className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>
              <div className="mt-4 leading-relaxed">
                <p className="text-base text-secondaryVariant">
                  Lorem ipsum dolor sit amet, consectetur cdolor col adipiscing
                  elit. Integer mattis nunc augue vel lacinia erat euismod ut.
                  Sed eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.Lorem ipsum dolor sit amet, consectetur cdolor col
                  adipiscing elit. Integer mattis nunc augue vel lacinia erat
                  euismod ut. Sed eleifend tellus nonole tincidunt aliquet.
                  Fusce aliquam mi felis.Lorem ipsum dolor sit amet, consectetur
                  cdolor col adipiscing elit. Integer mattis nunc augue vel
                  lacinia erat euismod ut. Sed eleifend tellus nonole tincidunt
                  aliquet. Fusce aliquam mi felis.Lorem ipsum dolor sit amet,
                  consectetur cdolor col adipiscing elit. Integer mattis nunc
                  augue vel lacinia erat euismod ut. Sed eleifend tellus nonole
                  tincidunt aliquet. Fusce aliquam mi felis.Lorem ipsum dolor
                  sit amet, consectetur cdolor col adipiscing elit. Integer
                  mattis nunc augue vel lacinia erat euismod ut. Sed eleifend
                  tellus nonole tincidunt aliquet. Fusce aliquam mi felis.Lorem
                  ipsum dolor sit amet, consectetur cdolor col adipiscing elit.
                  Integer mattis nunc augue vel lacinia erat euismod ut. Sed
                  eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.
                </p>
              </div>
            </details>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default TermsConditions;
