import React from "react";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import BookSelectors from "../../components/bookSelectors/BookSelectors";
import HeroBanner from "../../components/medEvaluationBanner/HeroBanner";

const MedEvaluationDate = () => {
  return (
    <>
      <MainWrapper>
        <div>
          <HeroBanner
            highlightedText=""
            title="Book Appointment"
            paragraphText=""
            showStats={true}
            bgClass="https://a360csastorage.blob.core.windows.net/childcare/46b1c025-41a4-47ae-a362-99a5d145dc48-fileName-med-date-banner.png"
          />
          <div className="container">
            <BookSelectors />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default MedEvaluationDate;
