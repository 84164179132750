import React, { useEffect, useState } from "react";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import ParentHistory from "../../../shared/components/Profiles-Components/ParentHistory";
import ParentProfileHistoryTable from "../../../shared/components/tables/ParentProfileHistoryTable";
import { fetchProfferUser } from "../../services/proffer.service";
import { IProffersOnVacancyRes } from "../../types/IProffer.interface";

const ParentProfileHistory = () => {
  // const [proffers, setProffers] = useState<IProffersOnVacancyRes[]>([])
  // const getProfferUser = async () => {
  //   const res = await fetchProfferUser()
  //   if (res?.ok) {
  //     setProffers(res?.data)
  //   }
  // }
  // useEffect(() => {
  //   getProfferUser()
  // }, [])
  return (
    <>
      <MainWrapper>
        <div>
          <ParentHistory />
          {/* <div className="container">
            <h2 className="text-[28px] text-secondary font-semibold mb-2">
              Your PrOffer History
            </h2>
            <p className="text-base text-secondaryVariant">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
            </p>
            <ParentProfileHistoryTable proffers={proffers}/>
          </div> */}
        </div>
      </MainWrapper>
    </>
  );
};

export default ParentProfileHistory;
