import React from "react";
import { BiEdit } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { LuUser } from "react-icons/lu";
import { RxDashboard } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { adventureRoutes } from "../../../adventures_module/routes/routes";
import { ccfRoutes } from "../../../ccfWebsite_module/routes/routes";
import { medEvalRoutes } from "../../../medEvaluation_module/routes/routes";
import { profferRoutes } from "../../../proffer_module/routes/routes";
import {
  clientID,
  identityServerURI,
  redirectURI,
} from "../../constants/environment.constants";
import { STORAGE_KEYS, USER_ROLES } from "../../enums/sharedEnums";
import { sharedRoutes } from "../../routes/routes";
import { signOut } from "../../services/auth.service";

interface Props {
  //   handleSignInClick: () => void;
  isOpen: boolean;
  setIsOpen: (check: boolean) => void;
}
const SecondaryHeader: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { User } = useSelector((state: RootState) => state?.auth);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const navigate = useNavigate();
  const paths = useLocation()?.pathname.split("/");
  const checkAuthorization = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
  const redirectUri = redirectURI;
  // const redirectURI = window.location.href
  const handleSignInClick = () => {
    // const checkAuthorization = localStorage.getItem(storageKeys?.accToken);
    if (!checkAuthorization) {
      localStorage.setItem(STORAGE_KEYS.PATH_NAME, window.location.pathname);
      window.location.replace(
        `${identityServerURI}${"?client_id=" + clientID}${"&redirect_URI=" + redirectUri
        }`
      );
    } else {
      toggleMenu();
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    signOut();
    toggleMenu();
    handleNav();
  };

  const handleGoToDashboard = () => {
    if (role === USER_ROLES.CENTER) {
      navigate(medEvalRoutes?.dashboard);
    } else if (role === USER_ROLES.PARENT) {
      return
      navigate(medEvalRoutes.dashboard);/// currently no functionality in dashboard
    } else if (role === USER_ROLES.TEACHER) {
      navigate(medEvalRoutes?.dashboard);
    }
  };
  const handleNav = () => {
    const pathCheck = window.location.pathname.split("/")[1];
    if (pathCheck.includes("pr")) navigate(profferRoutes.home);
    else if (pathCheck.includes("adv")) navigate(adventureRoutes.home);
    else if (pathCheck.includes("ccf")) navigate(ccfRoutes.home);
    else if (pathCheck.includes("md")) navigate(medEvalRoutes.eval_center_home);
    else navigate(ccfRoutes.home);
  };

  const handleNavToInBuild = (title: string) => {
    navigate(sharedRoutes.coming_soon, { state: { title } });
  };

  const handleNavToProfile = () => {
    setIsOpen(false);
    if (role && role === USER_ROLES.CENTER)
      navigate(sharedRoutes.center_profile);
    else {
      navigate(sharedRoutes.parent_profile);
    }
  };

  return (
    <>
      <div className=" bg-secondary">
        <div className="container flex flex-row py-1.5 justify-between items-center">
          <nav>
            <ul className="xl:flex items-center gap-4 xl:gap-7 hidden">
              <li>
                <Link
                  to={adventureRoutes.home}
                  className="text-lg text-white hover:text-primary"
                >
                  Adventures
                </Link>
              </li>
              {role !== USER_ROLES.PARENT &&
                paths[paths?.length - 1] !== "parent" && (
                  <li>
                    <Link
                      to={medEvalRoutes.eval_center_home}
                      className="text-lg text-white hover:text-primary"
                    >
                      Medical Evaluation
                    </Link>
                  </li>
                )}
              {/* {role !== USER_ROLES.TEACHER && paths[paths?.length - 1] !== "teacher" &&
                < li >
                  <Link
                    to={profferRoutes.home}
                    className="text-lg text-white hover:text-primary"
                  >
                    PrOffer
                  </Link>
                </li>
              }
              <li>
                <button
                  onClick={() => {
                    handleNavToInBuild("Foray");
                  }}
                  className="text-lg text-white hover:text-primary"
                >
                  Foray
                </button>
              </li> */}
              {/* <li>
                <Link to="/" className="text-lg text-white">
                  LMS
                </Link>
              </li> */}

              {/* <li>
                <Link to="/" className="text-lg text-white">
                  Child Care Employment
                </Link>
              </li>
              <li>
                <Link to="/" className="text-lg text-white">
                  Website Purchases
                </Link>
              </li>
              <li>
                <Link to="/" className="text-lg text-white">
                  JCC Operations
                </Link>
              </li> */}
            </ul>
          </nav>
          <div className="relative" id="dropdown-menu">
            <div
              className="inline items-center overflow-hidden w-[193px]"
            // onMouseOver={() => setIsOpen(true)}
            // onMouseLeave={() => setIsOpen(false)}
            >
              <button
                id="menu-toggle-button"
                onClick={handleSignInClick}
                className="flex items-center text-white text-lg font-semibold gap-1 bg-primary px-3 py-2 rounded-md"
              >
                <LuUser className="w-6 h-6 text-white" />
                <span className="max-w-[193px]">
                  {checkAuthorization
                    ? `${User?.name.split(" ")[0] || ""}`
                    : "Login / Register"}
                </span>
                {/* <span className="sr-only">Menu</span> */}
                {checkAuthorization ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <></>
                )}
              </button>
            </div>

            {/* <div className="inline-flex items-center overflow-hidden "> */}
            {/* <button
                  id="menu-toggle-button"
                  className="flex items-center gap-3 text-base text-secondary font-medium"
                  onClick={toggleMenu}
                >
                  <img
                    className="rounded-full w-9 h-9 border-2 border-primary"
                    src="../images/profile-img.png"
                    alt="profile avatar"
                  />
                  <span>Noor ul ain</span>

                  <span className="sr-only">Menu</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button> */}
            {/* </div> */}

            {isOpen && (
              <div
                id="menu-items"
                className="absolute end-0 z-10 mt-2 w-[218px] rounded-b-md border border-gray-100 bg-white shadow-lg p-4"
                role="menu"
              >
                <div className="flex justify-center items-center flex-col border-b border-[0.5] border-secondaryNeutral mb-4 pb-4">
                  <img
                    className="w-14 h-14 rounded-full"
                    src={
                      User?.profilePictureUrl ||
                      "https://a360csastorage.blob.core.windows.net/childcare/c5c02b9f-6960-4796-9779-23d131f93a3d-fileName-empty-profile-img.png"
                    }
                    alt="profile"
                  />
                  {/* ../images/Adventures_images/adventure-profile-img.png */}
                  <span className="text-base text-secondary font-semibold">
                    {User?.name || ""}
                  </span>
                </div>
                <ul className="">
                  {role !== USER_ROLES.PARENT &&
                    <li className="group">
                      <button
                        onClick={handleGoToDashboard}
                        className="flex items-center gap-2 text-base text-secondaryVariant group-hover:bg-primary group-hover:text-white w-full py-3 rounded-md px-1"
                      >
                        <RxDashboard className="w-6 h-6 " />
                        Go to Dashboard
                      </button>
                    </li>
                  }
                  <li className="group">
                    <button
                      onClick={handleNavToProfile}
                      className="flex items-center gap-2 text-base text-secondaryVariant group-hover:bg-primary group-hover:text-white w-full py-3 rounded-md px-1"
                    >
                      <BiEdit className="w-6 h-6 " />
                      Edit Profile
                    </button>
                  </li>
                  <li className="group">
                    <button
                      className="flex items-center gap-2 text-base text-secondaryVariant group-hover:bg-primary group-hover:text-white w-full py-3 rounded-md px-1"
                      onClick={handleSignOut}
                    >
                      <FiLogOut className=" w-6 h-6" />
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondaryHeader;
