import React, { useState, useEffect } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";
import { HiOutlineInbox } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adventureRoutes } from "../../../../adventures_module/routes/routes";
import { ccfRoutes } from "../../../../ccfWebsite_module/routes/routes";
import { medEvalRoutes } from "../../../../medEvaluation_module/routes/routes";
import { profferRoutes } from "../../../../proffer_module/routes/routes";
import { STORAGE_KEYS, USER_ROLES } from "../../../enums/sharedEnums";
import { sharedRoutes } from "../../../routes/routes";
import { signOut } from "../../../services/auth.service";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { forayRoutes } from "../../../../foray_module/routes/routes";
import { RiHandHeartLine } from "react-icons/ri";
import { PiBuildingOffice } from "react-icons/pi";
import { IoCheckboxOutline } from "react-icons/io5";
import { IoChevronBack } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";

const Sidebar = ({ isAbsolute, toggleSidebar, width, isExpanded }: any) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);

  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (dashboard: string) => {
    if (dashboard === "pr") {
      navigate(profferRoutes.dashboard);
    } else if (dashboard === "md") {
      navigate(medEvalRoutes.dashboard);
    } else if (dashboard === "fr") {
      navigate(forayRoutes.center_view);
    } else if (dashboard === "adv") {
      navigate(adventureRoutes.home);
    }
  };

  const handleNavToInBuild = (title: string) => {
    navigate(sharedRoutes.coming_soon, { state: { title } });
  };

  const handleSignOut = () => {
    signOut();
    handleNav();
  };

  const handleNav = () => {
    const pathCheck = window.location.pathname.split("/")[1];
    if (pathCheck.includes("pr")) navigate(profferRoutes.home);
    else if (pathCheck.includes("adv")) navigate(adventureRoutes.home);
    else if (pathCheck.includes("ccf")) navigate(ccfRoutes.home);
    else if (pathCheck.includes("md")) navigate(medEvalRoutes.eval_center_home);
    else navigate(ccfRoutes.home);
  };

  return (
    <div
      className={`bg-white flex flex-col h-screen transition-all duration-300 fixed  top-0 left-0 z-[9999] shadow-lg`}
      style={{ width }}
    >
      <div className="relative px-4 pt-6 pb-8 ">
        <div className="flex items-center justify-between">
          <img
            src={
              isExpanded
                ? "https://a360csastorage.blob.core.windows.net/childcare/web-logo.png"
                : "https://a360csastorage.blob.core.windows.net/childcare/ac2d5d93-6f02-4724-859b-9de264ef1632-fileName-collapse-web-logo.png"
            }
            alt="web logo"
            className="w-auto"
          />
          <button
            onClick={toggleSidebar}
            className={`rounded-full bg-primary p-1 text-white absolute -right-3 top-7 transition-transform `}
          >
            {isExpanded ? (
              <FaChevronLeft className="w-3.5 h-3.5" />
            ) : (
              <FaChevronRight className="w-3.5 h-3.5" />
            )}
          </button>
        </div>
      </div>

      <ul className="flex items-start flex-col gap-3 px-4 flex-1 scroll overflow-y-auto overflow-x-hidden">
        <button className="text-lg text-secondaryVariant font-semibold bg-secondaryNeutral rounded-md py-2 px-3 max-w-[183px] flex items-center gap-1 me-2">
          {isExpanded ? (
            <span
              className="flex items-center gap-1"
              onClick={() => {
                navigate(ccfRoutes.home);
              }}
            >
              <IoChevronBack className="w-4 h-6" />
              Back to website{" "}
            </span>
          ) : (
            <span>
              <CgWebsite className="w-6 h-6" />
            </span>
          )}
        </button>
        <li className="w-full">
          <Link
            to="#"
            className={`text-lg text-secondary font-medium flex items-center gap-3 w-full py-[4px] ${
              isExpanded ? "" : "justify-center"
            }`}
          >
            <MdOutlineDashboardCustomize className="w-6 h-6" />
            {isExpanded && "Dashboard"}
          </Link>
        </li>
        <li className="w-full">
          <details
            className="group [&_summary::-webkit-details-marker]:hidden"
            open
          >
            <summary
              className={`text-lg text-secondary font-medium flex items-center gap-3 w-full py-[4px] ${
                isExpanded ? "justify-between" : "justify-center"
              }`}
            >
              <span className="text-lg text-secondary font-medium flex items-center gap-3 ">
                <RiHandHeartLine className="w-6 h-6" />
                {isExpanded && "Complimentary"}
              </span>
              {isExpanded && (
                <svg
                  className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </summary>
            {isExpanded && (
              <div className=" leading-relaxed py-[4px]">
                <ul className="space-y-1">
                  <li
                    className={`text-base hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full ${
                      location.pathname.includes("/adv")
                        ? "text-primary bg-primaryVariant2 font-medium"
                        : "text-secondaryVariant"
                    }`}
                  >
                    <button
                      className="w-full text-start"
                      onClick={() => handleClick("adv")}
                    >
                      Adventures
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </details>
        </li>
        {/* <li className="w-full">
          <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary
              className={`text-lg text-secondary font-medium flex items-center gap-3 w-full py-[4px] ${
                isExpanded ? "justify-between" : "justify-center"
              }`}
            >
              <span className="text-lg text-secondary font-medium flex items-center gap-3">
                <PiBuildingOffice className="w-6 h-6" />
                {isExpanded && "Center Services"}
              </span>
              {isExpanded && (
                <svg
                  className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </summary>
            {isExpanded && (
              <div className=" leading-relaxed py-[4px]">
                <ul className="space-y-1">
                  {role === USER_ROLES.TEACHER && (
                    <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                      <button
                        onClick={() =>
                          handleNavToInBuild("Child Care Employment")
                        }
                        className="w-full text-start"
                      >
                        Child Care Employment
                      </button>
                    </li>
                  )}
                  <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                    <button
                      onClick={() => handleNavToInBuild("LMS")}
                      className="w-full text-start"
                    >
                      LMS
                    </button>
                  </li>
                  <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                    <button
                      onClick={() => handleNavToInBuild("Foray")}
                      className="w-full text-start"
                    >
                      Foray
                    </button>
                  </li>
                  <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                    <button
                      onClick={() => handleNavToInBuild("JCC Operations")}
                      className="w-full text-start"
                    >
                      JCC Operations
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </details>
        </li> */}
        <li className="w-full">
          <details
            className="group [&_summary::-webkit-details-marker]:hidden"
            open
          >
            <summary
              className={`text-lg text-secondary font-medium flex items-center gap-3 w-full py-[4px]  ${
                isExpanded ? "justify-between" : "justify-center"
              }`}
            >
              <span className="text-lg text-secondary font-medium flex items-center gap-3">
                <IoCheckboxOutline className="w-6 h-6" />
                {isExpanded && "Available"}
              </span>
              {isExpanded && (
                <svg
                  className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </summary>
            {isExpanded && (
              <div className=" leading-relaxed py-[4px]">
                <ul className="space-y-1">
                  {/* {role === USER_ROLES.TEACHER && (
                    <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                      <button
                        onClick={() =>
                          handleNavToInBuild("Child Care Employment")
                        }
                        className="w-full text-start"
                      >
                        Available
                      </button>
                    </li>
                  )} */}
                  {role !== USER_ROLES.PARENT && (
                    <li
                      className={`text-base hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full ${
                        location.pathname.includes("/md")
                          ? "text-primary bg-primaryVariant2 font-medium"
                          : "text-secondaryVariant"
                      }`}
                    >
                      <button
                        className="w-full text-start"
                        onClick={() => handleClick("md")}
                      >
                        Medical Evaluations
                      </button>
                    </li>
                  )}
                  <li className="py-2 pl-10 pr-4 rounded-md w-full flex items-center gap-3">
                    <span className="text-base text-[#DADADA]">PrOffer</span>
                    <span className="text-xs text-primary rounded-full bg-[#FFFAF8] py-0.5 px-2">
                      Coming soon
                    </span>
                  </li>
                  {/* <li
                    className={`text-base  hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full ${
                      location.pathname.includes("/fr/setup/")
                        ? "text-primary bg-primaryVariant2"
                        : "text-secondaryVariant"
                    }`}
                  >
                    <button
                      onClick={() => handleClick("fr")}
                      className="w-full text-start"
                    >
                      Setup / Modify
                    </button>
                  </li> */}
                  {/* <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                    <button
                      onClick={() => handleNavToInBuild("JCC Operations")}
                      className="w-full text-start"
                    >
                      Enrollment Tracking
                    </button>
                  </li>
                  <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                    <button
                      onClick={() => handleNavToInBuild("JCC Operations")}
                      className="w-full text-start"
                    >
                      Human Resouces
                    </button>
                  </li>
                  <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                    <button
                      onClick={() => handleNavToInBuild("JCC Operations")}
                      className="w-full text-start"
                    >
                      Checklist
                    </button>
                  </li>
                  <li className="text-base text-secondaryVariant hover:bg-primaryVariant2 hover:text-primary py-2 pl-10 pr-4 rounded-md w-full">
                    <button
                      onClick={() => handleNavToInBuild("JCC Operations")}
                      className="w-full text-start"
                    >
                      Task Generation
                    </button>
                  </li> */}
                </ul>
              </div>
            )}
          </details>
        </li>
      </ul>
      <ul className="flex flex-col items-start gap-3 px-4">
        <li className="py-3 w-full">
          <Link
            to={sharedRoutes.contact_us}
            className="text-lg text-secondary font-medium flex items-center gap-3"
          >
            <BiInfoCircle className="w-6 h-6" />
            {isExpanded && "Help Centre"}
          </Link>
        </li>
        <li className="py-3 w-full">
          <button
            className="text-lg text-dangerAlert font-medium flex items-center gap-3"
            onClick={handleSignOut}
          >
            <img src="../../images/logout-icon.svg" alt="logout" />
            {isExpanded && "Log out"}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
