import React, { useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  clientID,
  redirectURI,
  userProfileUpdateURI,
} from "../../constants/environment.constants";
import { postFiles } from "../../services/auth.service";

const ParentHistory = () => {
  const { User } = useSelector((state: RootState) => state.auth);

  const handleEditClick = () => {
    window.location.replace(
      `${userProfileUpdateURI}${"?client_id=" + clientID}${
        "&redirect_uri=" + redirectURI
      }&edit=${true}&cancel=${window.location.href}`
    );
  };

  return (
    <>
      <div className="container py-6 md:py-12 lg:py-16 flex flex-col md:flex-row gap-6 md:gap-10 xl:gap-16">
        <div className=" flex mx-auto justify-center items-center gap-4">
          <div className=" rounded-full bg-[#FFA78D]">
            <img
              className="w-full rounded-full max-w-[200px] max-h-[200px]"
              src={
                User?.profilePictureUrl ||
                "https://a360csastorage.blob.core.windows.net/childcare/c5c02b9f-6960-4796-9779-23d131f93a3d-fileName-empty-profile-img.png"
              }
              alt="parent profile"
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <h2 className="text-xl md:text-2xl xl:text-[28px] text-secondary font-semibold mb-1">
              {User?.name || ""}
            </h2>
            <button
              className="text-base text-statusColor font-medium"
              onClick={handleEditClick}
            >
              Edit Profile
            </button>
          </div>
          <p className="text-base text-secondaryVariant mb-4">
            {User?.email || ""}
          </p>
          <span className="text-white text-base font-medium bg-successAlert py-1 px-6 rounded-full">
            Verified
          </span>
          <hr className="my-4 md:my-7 xl:my-10 " />
          <div className="grid grid-cols-12 items-center gap-3 md:gap-6">
            <div className="col-span-12 sm:col-span-6 md:col-span-4">
              <h3 className="flex items-center gap-2 text-base sm:text-lg font-medium text-secondary mb-2">
                <FiPhoneCall className="text-secondaryVariant2 w-6 h-6" />
                Phone Number
              </h3>
              <span className="text-base text-secondaryVariant">
                {User?.contactNumber || ""}
              </span>
            </div>

            <div className="col-span-12 sm:col-span-6 md:col-span-4">
              <h3 className="flex items-center gap-2 text-base sm:text-lg font-medium text-secondary mb-2">
                <GrLocation className="w-6 h-6 text-secondaryVariant2" />
                ZIP Code{" "}
              </h3>
              <span className="text-base text-secondaryVariant">
                {User?.postalCode || ""}
              </span>
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-4">
              <h3 className="flex items-center gap-2 text-base sm:text-lg font-medium text-secondary mb-2">
                <img src="../images/globe-city-icon.svg" alt="location" />
                City{" "}
              </h3>
              <span className="text-base text-secondaryVariant">
                {User?.city || ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentHistory;
