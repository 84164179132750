import { FiPhoneCall } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { PiUserThin } from "react-icons/pi";
import { HiOutlineMail } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import {
  centerProfileUpdateURI,
  clientID,
  redirectURI,
  googleAPIKey,
} from "../../../shared/constants/environment.constants";
import { useEffect, useState } from "react";
import { fetchAccountInfo } from "../../../shared/services/auth.service";
import { renderStars } from "../../../shared/utils/ratingStars";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

const CenterProfile = () => {
  const { User } = useSelector((state: RootState) => state.auth);
  const [accountInfo, setAccountInfo] = useState<any>();
  const [placeDetails, setPlaceDetails] = useState<any>(null);
  const [showMoreReviews, setShowMoreReviews] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleAPIKey, // Replace with your Google Maps API key
    libraries: ["places"], // Ensure the Places library is included
  });

  const getAccountInfo = async () => {
    const res = await fetchAccountInfo();
    if (res?.ok) {
      setAccountInfo(res?.data);

      // Fetch place details if placeId is available
      if (res?.data?.center?.placeId && isLoaded) {
        console.log("check id: ", res?.data?.center?.placeId);
        fetchPlaceDetails(res.data.center.placeId);
      }
    }
  };

  const fetchPlaceDetails = (placeId: string) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const request = {
      placeId,
      fields: ["review", "user_ratings_total", "rating"], // Specify the fields you need
    };

    service.getDetails(request, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place
      ) {
        console.log("check place: ", place);
        setPlaceDetails(place);
      } else {
        console.error("Failed to retrieve place details:", status);
      }
    });
  };

  useEffect(() => {
    getAccountInfo();
  }, [isLoaded]);

  const handleEditClick = () => {
    window.location.replace(
      `${centerProfileUpdateURI}${"?client_id=" + clientID}${
        "&redirect_uri=" + redirectURI
      }&edit=${true}&cancel=${window.location.href}`
    );
  };

  const handleShowMoreReviews = () => {
    setShowMoreReviews(true);
  };

  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 flex flex-row gap-16">
          <div className="flex-1 overflow-y-auto h-[calc(100vh-115px)] custom-scrollbar">
            <h3 className="text-2xl text-secondary font-semibold flex justify-between items-center mb-6">
              Center Profile
              <button
                className="text-base text-statusColor font-medium"
                onClick={handleEditClick}
              >
                Edit Profile
              </button>
            </h3>
            <h2 className="text-[28px] text-secondary font-semibold mb-6">
              {accountInfo?.center?.centerName || ""}
            </h2>
            <div className="flex items-center gap-5 mb-6">
              <div className="flex items-center">
                {renderStars(accountInfo?.center?.rating || 0)}
              </div>
              <span className="text-base text-secondaryVariant">
                {placeDetails?.user_ratings_total || "No"} reviews
              </span>
              <button
                className="text-base underline text-statusColor"
                onClick={handleShowMoreReviews}
              >
                see more reviews
              </button>
            </div>

            <hr className="my-6 " />
            <div className="grid grid-cols-3 mb-6">
              <div>
                <h3 className="flex items-center gap-2 text-lg font-medium text-secondary mb-2">
                  <HiOutlineMail className="w-6 h-6 text-secondaryVariant2" />
                  Center Email
                </h3>
                <span className="text-base text-secondaryVariant">
                  {accountInfo?.email || ""}
                </span>
              </div>

              <div>
                <h3 className="flex items-center gap-2 text-lg font-medium text-secondary mb-2">
                  <GrLocation className="w-6 h-6 text-secondaryVariant2" />
                  ZIP Code{" "}
                </h3>
                <span className="text-base text-secondaryVariant">
                  {accountInfo?.center?.location?.postalCode || ""}
                </span>
              </div>

              <div>
                <h3 className="flex items-center gap-2 text-lg font-medium text-secondary mb-2">
                  <img src="../images/globe-05.svg" alt="location" />
                  City{" "}
                </h3>
                <span className="text-base text-secondaryVariant">
                  {accountInfo?.center?.location?.city || ""}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-3 mb-6 gap-6">
              {accountInfo?.center?.imageUrls?.length > 0 &&
                accountInfo?.center?.imageUrls?.map(
                  (url: string, index: number) => (
                    <div key={index}>
                      <img
                        className="w-full h-full max-h-[275px] object-cover"
                        src={url}
                        alt="center"
                      />
                    </div>
                  )
                )}
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-secondary mb-4">
                Admin
              </h3>
              <div className="flex items-center gap-9">
                <img
                  className="max-w-[85px] max-h-[85px] rounded-full w-full"
                  src={
                    accountInfo?.profilePictureUrl ||
                    "https://a360csastorage.blob.core.windows.net/childcare/c5c02b9f-6960-4796-9779-23d131f93a3d-fileName-empty-profile-img.png"
                  }
                  alt="profile"
                />
                <div className="flex items-start gap-9">
                  <div>
                    <h3 className="flex items-center gap-2 text-lg font-medium text-secondary mb-2">
                      <PiUserThin className="text-secondaryVariant2 w-6 h-6" />
                      Admin Name:
                    </h3>
                    <span className="text-base text-secondaryVariant">
                      {accountInfo?.name || ""}
                    </span>
                  </div>
                  <div>
                    <h3 className="flex items-center gap-2 text-lg font-medium text-secondary mb-2">
                      <FiPhoneCall className="text-secondaryVariant2 w-6 h-6" />
                      Phone Number:
                    </h3>
                    <span className="text-base text-secondaryVariant">
                      {accountInfo?.contactNumber || ""}
                    </span>
                  </div>

                  {accountInfo?.isVerified && (
                    <div className="text-white bg-successAlert rounded-full ps-1 pe-2 py-0.5 flex items-center gap-1 ">
                      <img src="../images/verify-icon.svg" alt="verify icon" />{" "}
                      Verified{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showMoreReviews && placeDetails?.reviews && (
              <div className="mt-8 ">
                <h3 className="text-xl font-semibold text-secondary mb-4 flex gap-4 items-baseline ">
                  <p>Reviews</p>{" "}
                  <p
                    className="text-sm  underline text-secondaryVariant cursor-pointer hover:shadow-btnShadow"
                    onClick={() => {
                      setShowMoreReviews(false);
                    }}
                  >
                    Hide Reviews
                  </p>
                </h3>
                <ul className="h-44 overflow-y-scroll">
                  {placeDetails?.reviews?.map((review: any, index: number) => (
                    <li key={index} className="mb-4">
                      <div className="flex items-center gap-2">
                        <span className="text-lg font-medium">
                          {review?.author_name}
                        </span>
                        <span className="text-base text-secondaryVariant flex">
                          {renderStars(review?.rating)}
                        </span>
                      </div>
                      <p className="text-base text-secondaryVariant">
                        {review?.text}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default CenterProfile;
