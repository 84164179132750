export const ERR_MSGS = {
    Center_Name_Length: "Please enter a valid name. Max length 100 characters",
    City_Select: "Select a city",
    Invalid_Email: "The email entered is invalid",
    Invalid_Password: "The password entered is invalid",
    Invalid_Password_Pattern: "The password pattern is invalid",
    Login_Error: "Incorrect email or password",
    Name_length: "Please enter a valid name (3 - 50 characters)",
    Password_Pattern_Hint: "The password should contain:\n1. At least 8 characters\n2. At least 1 uppercase character\n3. At least 1 lowercase character\n4. At least 1 special character",
    Password_Does_Not_Match: "Password doesn't match",
    Phone_Number_Length: "Please enter a valid contact number. Max length 15 characters",
    Postal_Code:"Postal Code in Invalid",
    Registration_Details: "Verify the entered details and try again",
    State_Select: "Select a state",
    Empty_Field: "This field is required",
    Something_Went_Wrong: "Something went wrong, please try again later",
    Complete_Your_Profile_Appointment:"'Please complete your profile information in edit profile to Book your appointment'"
}