import moment from "moment";
import { medEvalApi } from "../../shared/utils/axios";
import { Appointment } from "../types/medEval.interface";

const validateSelections = async (
  state: string,
  service: string,
  appointmentDTime: Appointment
) => {
  try {
    const resp: any = await medEvalApi.post(`/validateMedicalEvaluation`, {
      service: service,
      state: state,
      appointment: appointmentDTime,
    });
    console.log("res: ", resp);
    if (resp.ok && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const getMyAppointments = async () => {
  try {
    const resp: any = await medEvalApi.get(`/myAppointments`);

    if (resp.ok && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const getAvailableSlots = async (date: Date | string) => {
  try {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const resp: any = await medEvalApi.get(`/availableSlots/${formattedDate}`);

    if (resp.ok && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

const rescheduleAppointment = async (
  appointmentId: string,
  state: string,
  service: string,
  appointmentDTime: Appointment
) => {
  try {
    const resp: any = await medEvalApi.patch(
      `/rescheduleAppointment/${appointmentId}`,
      {
        service: service,
        state: state,
        appointment: appointmentDTime,
      }
    );

    if (resp.ok && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

export const medicalEvalSvc = {
  validateSelections,
  getMyAppointments,
  getAvailableSlots,
  rescheduleAppointment,
};
