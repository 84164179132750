import { Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import CenterProfile from "../proffer_module/pages/Profiles/CenterProfile";
import ParentProfileHistory from "../proffer_module/pages/Profiles/ParentProfileHistory";
import LoaderSpinner from "./components/Loader/Loader";
import { sharedRoutes } from "./routes/routes";
import ProtectedRoute from "./components/RouteProtection";
import { USER_ROLES } from "./enums/sharedEnums";
import { ccfRoutes } from "../ccfWebsite_module/routes/routes";

const SharedModuleRoutes: RouteObject[] = [

    {
        path: "/",
        element: <ProtectedRoute role={USER_ROLES.CENTER} />,
        children: [
            {
                path: sharedRoutes.center_profile,
                element: (
                    <Suspense fallback={<LoaderSpinner />}>
                        <CenterProfile />
                    </Suspense>
                ),
            },
        ]
    },
    {
        path: "/",
        element: <ProtectedRoute role={USER_ROLES.PARENT} />,
        children: [
            {
                path: sharedRoutes.parent_profile,
                element: (
                    <Suspense fallback={<LoaderSpinner />}>
                        <ParentProfileHistory />
                    </Suspense>
                ),
            },]
    },
    { path: "*", element: <Navigate to={ccfRoutes.home} /> },


]

export default SharedModuleRoutes;
