import moment from "moment";

export const formatDateSlash = (date: string | Date) => {
  return moment(date).format("MM/DD/YY");
};
export const formatDateSlashFullYear = (date: string | Date) => {
  return moment(date).format("MM/DD/YYYY");
};
export const formatDateSpace = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("DD MMMM YYYY") || "";
};
export const formatDateTimeFull = (date: string | Date) => {
  return moment(date).format("MMMM, Do YYYY. hh:mma");
};
export const formatDateFull = (date: string | Date) => {
  return moment(date).format("MMMM, Do YYYY");
};
export const formatDateAndTime = (date: string | Date) => {
  if (!date) return { date: "", timeSlot: "" };
  const parsedDate = moment(date, "ddd MMM DD YYYY/h:mma");
  return {
    date: parsedDate.format("YYYY-MM-DD"),
    timeSlot: parsedDate.format("hh:mma").toUpperCase(),
  };
};

export const formatDateBlog = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("MMMM DD, YYYY") || "";
};

export const revertToDateType = (date: string) => {
  if (!date) return null;
  return moment(date).toDate()
}