export const medEvalRoutes = {
  eval_center_home: "/md/home",
  eval_teacher_home: "/md/evaluation-teacher",
  date: "/md/date",
  book_appointment_parent: "/md/book-appointment-parent",
  book_appointment_center: "/md/book-appointment-center",
  dashboard: "/md/dashboard",
  confirm_booking: "/md/confirm-booking",
  confirm_booking_dashboard:"/md/confirm-booking-dashboard",
  eval_successful: "/md/evaluation-successful",
  center_appointment: "/md/center-appointment",

  // center_dashboard: "/md/center-dashboard",
  // center_history: "/md/center-history",
  // eval_dashboard: "/md/dashboard",
};