import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React, { useEffect, useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { FiMail } from "react-icons/fi";
import { HiChevronDown } from "react-icons/hi";
import { LuHeartPulse, LuUser } from "react-icons/lu";
import { TbCalendarHeart } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { RootState } from "../../../../store/store";
import BeforeLoginModal from "../../../proffer_module/components/Modals/BeforeLogin";
import { formatDateAndTime } from "../../../shared/utils/formatDate";
import { showErrorToast } from "../../../shared/utils/toast";
import { initBookAppointment, initBookAppointmentError } from "../../constants/initialForms";
import { medEvalRoutes } from "../../routes/routes";
import { medicalEvalSvc } from "../../services/medEvals.service";
import Calendar from "./Calendar";
import { regexPatterns } from "../../../shared/constants/regexPatterns";
import { ERR_MSGS } from "../../../shared/constants/errorMsgs";
import { Service } from "../../enums/service.enum";


const BookSelectors: React.FC = () => {
  const [bookAppointmentForm, setBookAppointmentForm] =
    useState(initBookAppointment);
  const [bookAppointmentFormErrors, setBookAppointmentFormError] = useState(initBookAppointmentError)
  const [stateOptions, setStateOptions] = useState<
    { value: string; label: string }[]
  >([{ value: "Maryland", label: "Maryland" }]);
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { User } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const serviceOptions = [
    { value: Service.MEDICAL_EVALUATION, label: "Medical Evaluation" },
  ];

  //Currently only Maryland will be there. Uncomment to get all the states
  // const handleSetStates = () => {
  //   const temp = Object.values(states);
  //   const stateOptions = temp.map((item) => ({ value: item, label: item }));
  //   setStateOptions(stateOptions);
  // };
  // useEffect(() => {
  //   handleSetStates();
  // }, []);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBookAppointmentForm((prev) => ({ ...prev, [name]: value }));
  }

  const handleSelect = (value: string, name: string) => {
    setBookAppointmentForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const { name } = e.target;
    setBookAppointmentFormError((prev) => ({ ...prev, [`${name}Error`]: "" }));
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const { name, value } = e.target;
    if (name === "name" && !regexPatterns.name.test(value)) setBookAppointmentFormError((prev) => ({ ...prev, nameError: ERR_MSGS.Name_length }))
    if (name === "email" && !regexPatterns.email.test(value)) setBookAppointmentFormError((prev) => ({ ...prev, emailError: ERR_MSGS.Invalid_Email }))
  }

  const handleDateTimeSelection = (date: Date, time?: string) => {
    const dateTimeString = `${date.toDateString()}/${time || ""}`;
    setSelectedDateTime(dateTimeString);
    setBookAppointmentForm((prev) => ({ ...prev, dateTime: dateTimeString }));
    if (time) {
      setIsOpen(false);
    }
  };

  const handleOpenBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
    localStorage.setItem("filledInfo", JSON.stringify(bookAppointmentForm));
  };

  const handleCloseBeforeLoginModal = () => {
    setShowBeforeLoginModal(false);
    localStorage.removeItem("filledInfo");
  };

  const handleBookAppointment = () => {
    if (!User?._id) {
      handleOpenBeforeLoginModal();
    } else if (!User?.name) {
      showErrorToast(
        "Please complete your profile information in edit profile to Book your appointment"
      );
    } else {
      handleBooking();
    }
  };

  const handleBooking = async () => {
    const appointment = formatDateAndTime(bookAppointmentForm?.dateTime);

    const resp = await medicalEvalSvc.validateSelections(
      bookAppointmentForm?.state,
      bookAppointmentForm?.service,
      appointment
    );
    if (resp?.validate && resp?.validate === true) {
      console.log("valid");
      navigate(medEvalRoutes?.confirm_booking, {
        state: {
          teachers: [{
            state: bookAppointmentForm?.state,
            service: bookAppointmentForm?.service,
            date: appointment?.date,
            timeSlot: appointment?.timeSlot,
            teacherName: bookAppointmentForm?.name,
            teacherEmail: bookAppointmentForm?.email,
          }],
        }
      });
    }
    console.log(resp);
  };

  useEffect(() => {
    const checkFormCompletion = () => {
      const { state, service, dateTime } = bookAppointmentForm;
      if (state && service && dateTime) {
        return true;
      } else {
        return false;
      }
    };

    setIsFormComplete(checkFormCompletion());
  }, [bookAppointmentForm]);

  useEffect(() => {
    if (!localStorage.getItem("filledInfo")) return;
    const temp = JSON.parse(localStorage.getItem("filledInfo")!);
    setBookAppointmentForm(temp);
    setSelectedDateTime(temp?.dateTime);
    if (User?._id) localStorage.removeItem("filledInfo");
  }, [localStorage.getItem("filledInfo")]);

  useEffect(() => {
    if (!User?._id) return;
    setBookAppointmentForm((prev) => ({ ...prev, name: User?.name || "", email: User?.email || "" }))
  }, [User])

  return (
    <>
      <div className=" py-6 md:py-10 lg:py-16">
        <h2 className="text-[28px] text-secondary mb-7 font-semibold">
          Booking as a Teacher
          <h5 className="text-[15px] text-secondary mb-7">
            Available Tuesdays and Thursdays{" "}
          </h5>
        </h2>
        <div className="grid grid-cols-12 gap-4 mb-9">
          <div className="col-span-12 md:col-span-6 xl:col-span-4 relative">
            <input
              type="text"
              className="ps-9 pe-4 py-3.5 rounded-lg border-secondaryVariant2 border w-full"
              placeholder="Teacher Name"
              name="name"
              value={bookAppointmentForm?.name}
              onChange={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <span className="absolute left-2 top-3.5 text-primary">
              <LuUser className="w-6 h-6" />
            </span>
            {bookAppointmentFormErrors?.nameError && (
              <p className="text-red-600 pl-2">
                {bookAppointmentFormErrors?.nameError}
              </p>
            )}
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-4 relative">
            <input
              type="email"
              className="ps-10 pe-4 py-3.5 rounded-lg border-secondaryVariant2 border w-full"
              placeholder="Email"
              name="email"
              value={bookAppointmentForm?.email}
              onChange={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <span className="absolute left-2 top-4 text-primary">
              <FiMail className="w-6 h-6" />
            </span>
            {bookAppointmentFormErrors?.emailError && (
              <p className="text-red-600 pl-2">
                {bookAppointmentFormErrors?.emailError}
              </p>
            )}
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-4 w-full relative">
            <Select
              className="text-start w-full "
              placeholder="Select a State"
              isClearable
              options={stateOptions}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "grey" : "#CED2DA",
                  padding: "8px 0 8px 30px",
                  borderRadius: "8px",
                }),
              }}
              value={stateOptions.find(
                (opt) => opt.value === bookAppointmentForm.state
              )}
              onChange={(e) => {
                handleSelect(e?.value as string, "state");
              }}
            />
            <span className="absolute left-2 top-4 text-primary">
              <img
                src="../images/MedEvaluation_images/globe-05.svg"
                alt="globe"
              />
            </span>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-4 w-full relative">
            <Select
              className="text-start w-full"
              placeholder="Select a Service"
              isClearable
              options={serviceOptions}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "grey" : "#CED2DA",
                  padding: "8px 0 8px 30px",
                  borderRadius: "8px",
                }),
              }}
              value={serviceOptions.find(
                (opt) => opt.value === bookAppointmentForm.service
              )}
              onChange={(e) => {
                handleSelect(e?.value as string, "service");
              }}
            />
            <span className="absolute left-2 top-4 text-primary">
              <LuHeartPulse className="w-6 h-6" />
            </span>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-4 w-full relative">
            <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
              <DropdownMenu.Trigger
                className={`relative border pe-2 ps-9 py-3 min-h-[54px] w-full h-full font-inter border-[#CED2DA] text-left ${selectedDateTime ? "text-secondary" : "text-[#808080]"
                  } rounded-lg flex items-center justify-between`}
              >
                {selectedDateTime || "Select Date/Time"}{" "}
                <HiChevronDown size={24} />
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                className="absolute top-2 right-0 shadow-md  w-[355px] p-4 rounded-lg border border-secondaryVariant2/50 px-4 pb-6 bg-secondaryNeutral z-20"
                side="right"
                align="end"
              >
                <Calendar onDateTimeSelect={handleDateTimeSelection} />
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            <span className="absolute left-2 top-4 text-primary">
              <TbCalendarHeart className="w-6 h-6" />
            </span>
          </div>
        </div>
        <div>
          <button
            onClick={handleBookAppointment}
            className={`btnPrimary max-w-[250px] gap-1 ${!isFormComplete ? "opacity-50" : ""
              }`}
            disabled={!isFormComplete}
          >
            <CiCalendar className="w-5 h-5" />
            Book your appointment
          </button>
          {showBeforeLoginModal && (
            <BeforeLoginModal
              handleBeforeLoginModal={handleOpenBeforeLoginModal}
              onClose={handleCloseBeforeLoginModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BookSelectors;
